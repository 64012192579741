<template lang="pug">
include ../../../configs/template
div.row.text-left
  div.col-12
    +data-info('number', 'statement.number')
  div.col-12
    +data-info('medicalInstitution', 'medicalInstitutionByID(statement.medical_institution).value')
  div.col-12
    +data-info('position', 'positionMedicalByID(statement.position)[nameLang]')
  div(v-if="statement.created_at").col-sm-12.col-md-6
    +data-info('createDate', 'getDateFormat(statement.created_at?.split(" ")[1])')
  div.col-sm-12.col-md-6
    +data-info('dateReceipt', 'getDateFormat(statement.date_meeting)')
  div.col-12
    +data-info('payment', 'statement.is_payed ? $t("isPayed") : $t("notPayed")')
  div.col-12
    +data-info('status', 'statusById(statement.status_document)[nameLang]')(:class="getStatus(statement.status_document)")
  div(v-if="statement.comments?.length").col-sm-12
    div.col-12.p-0
      h5.p-0.m-0 {{$t('rejectionReasonStatement')}}:
      v-divider
      div(v-for="(comment, index) in statement.comments" :key="comment.id").ml-4
        div
          +data-info('rejectionReasonStatement','getDirectoryObject({ id: comment.additional_info.reason, value: "rejectionReasons" })[nameLang]')
        div(v-if="checkAccess('backOffice')").pt-2.py-3
          +data-info('createdBy', 'comment.created_by.name')
        div.pt-2.py-3
          +data-info('dateCreate', 'new Date(comment.created_by.date).toLocaleString()')
        div.pt-2.py-3
          +data-info('reasonComment', 'comment.comment')
        v-divider(v-if="index !== statement.comments.length-1")
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import { getDateFormat, getStatus } from '@/mixins/main'
import { checkAccess } from '@/mixins/permissions'
export default {
  props: {
    statement: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      checkAccess,
      getDateFormat,
      getStatus
    }
  },
  computed: {
    ...mapGetters(['medicalInstitutionByID', 'positionMedicalByID', 'statusById', 'getDirectoryObject']),
    ...mapState({
      nameLang: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr'
    })
  }
}
</script>
